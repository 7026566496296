import { graphql } from 'gatsby';
import * as React from 'react';
import Layout from '../components/global/layout/layout';
import Button from '../components/page/button/button';
import HeadingAndText from '../components/page/heading-and-text/heading-and-text';
import ImageHeader from '../components/page/image-header/image-header';
import { getHeaderImageSlice, getHeadingAndTextSlice } from '../utils/slices';

const videos = [
  {
    src: 'https://static.osterdach.de/2021-OsterDach-Mitarbeiter-Thomas-Brixius-V1.mp4',
    caption: 'Thomas',
    secondaryCaption: 'Auszubildender',
    poster: 'https://static.osterdach.de/thomas_brixius_cover.jpg'
  },
  {
    src: 'https://static.osterdach.de/2021-OsterDach-Mitarbeiter-Dominik-Weberskirch-V3.mp4',
    caption: 'Dominik',
    secondaryCaption: 'Metallexperte',
    poster: 'https://static.osterdach.de/dominik_weberskirch_cover.jpg'
  },
  {
    src: 'https://static.osterdach.de/2021-OsterDach-Mitarbeiter-Pascal-Geisen-V4.mp4',
    caption: 'Pascal',
    secondaryCaption: 'Zimmermeister',
    poster: 'https://static.osterdach.de/pascal_geisen_cover.jpg'
  },
  {
    src: 'https://static.osterdach.de/2021-OsterDach-Mitarbeiter-Jensen-Fritsch-V2.mp4',
    caption: 'David & Oliver',
    secondaryCaption: 'Holzbauexperten',
    poster: 'https://static.osterdach.de/jensen_fritsch_cover.jpg'
  },
  {
    src: 'https://static.osterdach.de/2021-OsterDach-Mitarbeiter-Azubis-V2.mp4',
    caption: 'Fynn & Ole',
    secondaryCaption: 'Auszubildende',
    poster: 'https://static.osterdach.de/azubis_cover.jpg'
  }
];

export const pageQuery = graphql`
  {
    jobs: allPrismicJob {
    nodes {
      uid
      data {
        titel {
          text
        }
        description {
          html
        }
        preview_image {
          url
        }
      }
    }
  }
}
`

export default ({ data }) => (
  <Layout title="Jobs" description="Aktuelle Stellenangebote der Oster Dach + Holzbau GmbH">
    <ImageHeader slice={getHeaderImageSlice('/images/musterhaus-uerzig.jpg')} />
    <HeadingAndText slice={getHeadingAndTextSlice(
      'Arbeiten im Osternest',
      'Wir sind ein eingespieltes Expertenteam und geben jeden Tag unser Bestes, für unsere Projekte und unsere Kunden. Mach dir selbst ein Bild und lerne uns kennen.'
    )} />
    <div className="container my-5" style={{
      lineHeight: '1.7rem'
    }}>
      <div className="row">
        {
          videos.map(({src, caption, secondaryCaption, poster}) => (
            <div className="col-12 col-md-6 col-xl-4 mb-5" key={src}>
              <video
                controls
                controlsList="nofullscreen"
                className="w-100"
                preload="metadata"
                poster={poster}
                style={{
                  position: 'relative',
                  zIndex: 2,
                  marginBottom: '-5px'
                }}
              >
                <source src={src} type="video/mp4"></source>
              </video>
              <span className="bg-green p-2 color-white">{caption}</span>
              {
                secondaryCaption ? (
                  <span style={{ backgroundColor: '#bdbdbd' }} className="p-2 color-white">{secondaryCaption}</span>
                ) : ''
              }
            </div>
          ))
        }
      </div>
    </div>
    <HeadingAndText slice={getHeadingAndTextSlice('Das macht unsere Arbeit aus')} />
    <div className="container my-5" style={{
      lineHeight: '1.7rem'
    }}>
      <div className="row">
        <div className="col-12 col-md-6 mb-5">
          <p>
            Schiefer, Ziegel, Faserzement, Zink, Aluminium, Blei, Zinn, Eisen, Holzfaser, EPS, XPS, Steinwolle, Bitumenbahn,
            Kunststoffbahn, Flüssigkunststoff, Vlies, Kies, Latten, Schalung, Konstruktionshölzer, Schrauben, Nägel, Winkel, Dübel... und vieles mehr...
          </p>
          <p>
            Diese Materialien bearbeiten unsere Nestbauexperten täglich in unseren Projekten. Die Arbeiten erstrecken sich von klassischen Dacheindeckungs-
            und Dachabdichtungsarbeiten an Neubau- und Bestandsgebäuden, über energetische Sanierung und Restauration von Dachstühlen bis hin zu
            Neubauerrichtung von Holzrahmenhäusern inklusive aller Dach- und Abdichtungsarbeiten.
          </p>
        </div>
        <div className="col-12 col-md-6 mb-5">
          <img className="w-100" style={{
            boxShadow: '0px 0px 23px 4px rgba(155,155,155,0.12)'
          }} src="/images/jobs/2016-08-05-tonimedia-OsterDach-Texturen-08696.jpg" alt=""></img>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-6 mb-5 order-md-2">
          <p>
            Das Oster-Team besteht aus etwa zehn Dachdecker- und zehn Zimmerergesellen, sowie drei Dachdecker- und drei Zimmererermeistern aller Alters- und Erfahrungsklassen.
          </p>
          <p>
            Beide Gewerke arbeiten Hand in Hand und die Arbeiten gehen fließend ineinander über. Dadurch lernen wir alle von einander und entwickeln Schnittstellen stetig weiter.
            Durch unser großes Tätigkeitsfeld können Schwerpunkte festgelegt und besondere Fähigkeiten gestärkt werden.
          </p>
          <p>
            Allrounder sind natürlich auch willkommen!
          </p>
        </div>
        <div className="col-12 col-md-6 mb-5">
          <img className="w-100" style={{
            boxShadow: '0px 0px 23px 4px rgba(155,155,155,0.12)'
          }} src="/images/jobs/schiefer.jpg" alt=""></img>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-6 mb-5">
          <p>
            Nachwuchsarbeit schreiben wir groß! Die Ausbildung junger Handwerker liegt uns besonders am Herzen und wird von all unseren Mitarbeitern unterstützt.
          </p>
          <p>
            Unser treuer privater Kundenstamm ermöglicht uns eine überwiegende Beschäftigung im nahen Umkreis von Bernkastel. Eine tägliche Heimkehr unserer Mitarbeiter zur Familie ist uns wichtig.
          </p>
        </div>
        <div className="col-12 col-md-6 mb-5">
          <img className="w-100" style={{
            boxShadow: '0px 0px 23px 4px rgba(155,155,155,0.12)'
          }} src="/images/jobs/2018-08-15-tonimedia-OsterDach-02661.jpg" alt=""></img>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-6 mb-5 order-md-2">
          <p>
            Wir freuen uns über eigenständige, motivierte und teamfähige Menschen, die Spaß an ihrem Handwerk, dem Kontakt zu Kunden, und der Zusammenarbeit mit tollen Kollegen haben.
          </p>
          <p>
            Alles weitere besprechen wir am liebsten im persönlichen Gespräch.
          </p>
        </div>
        <div className="col-12 col-md-6 mb-5">
          <img className="w-100" style={{
            boxShadow: '0px 0px 23px 4px rgba(155,155,155,0.12)'
          }} src="/images/jobs/2018-08-15-tonimedia-OsterDach-02907.jpg" alt=""></img>
        </div>
      </div>
    </div>
    <HeadingAndText slice={getHeadingAndTextSlice('Jetzt einsteigen')} />
    <div className="container mb-5">
      <div className="row">
        <div className="col-12 col-md-6">
          <p>
            Wir sind stets auf der Suche nach neuen Kolleginnen und Kollegen in den folgenden Bereichen und freuen uns auf ein persönliches Gespräch mit Ihnen:
          </p>
          <ul className="mb-5">
            <li>Auszubildende zum Zimmerer oder Dachdecker (m/w/d)</li>
            <li>Gesellen im Zimmerer- und Dachdeckerhandwerk (m/w/d)</li>
            <li>Zimmerermeister (m/w/d)</li>
            <li>Dachdeckermeister (m/w/d)</li>
            <li>Bauschreiner (m/w/d)</li>
          </ul>
          <Button text="Jetzt bewerben" link="mailto:info@osterdach.de" color="green" small />
        </div>
      </div>
    </div>
  </Layout>
);
